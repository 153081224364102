import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ListElement from "../header/ListElement";
import {addClass, removeClass} from "wipi-common/src/lib/util";

export class LanguageSelector extends Component {

    constructor(props) {
        super(props)
        if (document.getElementById("root")) {
            document.getElementById("root").onclick = this.disableContextMenu;
        }
    }


    disableContextMenu = (e) => {
        if (e.target?.parentElement && e.target.parentElement.id!=='lang-logo') {
            document.getElementById("dropdown").style.display = 'none'
        }
        removeClass(document.getElementById('lang-logo'),"dpag-principal-lang-logo-active")
    }

    showDropdown = (e) => {
        e.stopPropagation()
        document.getElementById('dropdown').style.display = 'block'
        addClass(document.getElementById('lang-logo'),"dpag-principal-lang-logo-active")
    }

    chooseLanguage = (e, element) => {
        e.stopPropagation()
        document.getElementById('dropdown').style.display = 'none'
        this.props.changeLanguage(element)
        removeClass(document.getElementById('lang-logo'),"dpag-principal-lang-logo-active")
    }

    render = () => {
        return <div id="lang-logo" className="dpag-principal-lang-logo" onClick={this.showDropdown}
                    onMouseOver={this.hover}
                    onMouseOut={this.unHover}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                 viewBox="0 0 24 24">
                <defs>
                    <path id="a" d="M.036.024H24V24H.036z"/>
                </defs>
                <g fill="none">
                    <path id="world" fill='#000'
                          d="M12.963 21.773s-1.56-2.373-.24-3.868c0 0-1.954-1.269-2.188-3.924 0 0 4.526-.99 6.243 1.023 1.714 2.01 3.144 2.273 4.154 1.503 1.011-.768-1.203 4.808-7.969 5.266zm-6.067-9.506c-4.24-.088-1.902-7.826-1.902-7.826 3.692-3.054 5.596-.878 5.596-.878 4.946.042 1.22 2.57 1.22 2.57l.284 1.75c-5.098.319-5.198 4.384-5.198 4.384zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0z"
                          mask="url(#b)"/>
                </g>
            </svg>
            <p id="selectedLanguage"
               className="selectedLanguage">{this.props.selectedLanguage.title}</p>
            <ul id="dropdown" className="dropdown-menu">
                {this.props.languageList?.map((e, index) => {
                    return <ListElement key={"lang-" + index} element={e}
                                        changeLanguage={this.chooseLanguage}
                                        index={index}
                                        selectedLanguage={this.props.selectedLanguage.value}
                                        languageCount={this.props.languageList.length}/>
                })}
            </ul>
        </div>
    }
}

LanguageSelector.propTypes = {
    languageList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })).isRequired,
    changeLanguage: PropTypes.func.isRequired,
    selectedLanguage: PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    }).isRequired
}

export default LanguageSelector