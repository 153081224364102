import React from 'react'
import PropTypes from 'prop-types'
import './FaceDetectionHint.scss'
export default function FaceDetectionHint({caseInfo}) {
    if (!caseInfo || !caseInfo.modules || !caseInfo.modules.identMethodSelection || !caseInfo.modules.identMethodSelection.methodChoice) {
        return null
    }
    return extractHintFromModule(caseInfo.modules.identMethodSelection.methodChoice);
}

function extractHintFromModule(methodChoice) {
    if (methodChoice) {
        let module = methodChoice.find(module => (module.method === "video"))
        return <>
            {module?.additionalInfo &&
            <p className={"face-detection-hint"} dangerouslySetInnerHTML={{__html: module?.additionalInfo}}/>}
        </>
    }
    return null
}

FaceDetectionHint.propTypes = {
    caseInfo: PropTypes.shape({
        modules: PropTypes.shape({
            identMethodSelection: PropTypes.shape({
                methodChoice: PropTypes.arrayOf(
                    PropTypes.shape({
                        method: PropTypes.string.isRequired,
                        additionalInfo:PropTypes.string
                    })
                ).isRequired
            }).isRequired
        }).isRequired
    }).isRequired
}
