import React, {Component} from 'react'
import PropTypes from 'prop-types'
import FormGroup from './FormGroup'
import Title from '../misc/Title'
import ShortCaseInfo from '../misc/ShortCaseInfo'
import {smoothScrollToTop} from '../misc/ScrollToTop'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n"
import Text from "../i18n/Text"
import I18nContext from "../i18n/I18nContext"

const DATA_COMPLETION_DOCUMENT_TITLE = 'POSTIDENT | '

export default class DataCompletion extends Component {

    static contextType = I18nContext

    state = {
        userData: {},
        isSubmitting: false
    }

    componentDidMount() {
        document.title = DATA_COMPLETION_DOCUMENT_TITLE + getI18nTextWithContext(this.context, 'dataCompletion.title')
    }

    componentDidUpdate(prevProps) {
        if (prevProps.serverValidationErrors !== this.props.serverValidationErrors) {
            this.mergeServerValidation()
        }
    }

    mergeServerValidation = () => {
        this.props.serverValidationErrors.forEach(error => {
            const value = this.state.userData[error.parameterName]?.value
            this.validateUserData(error.parameterName, null, value, error.errorText)
        })
    }

    submitData = e => {
        e.preventDefault()
        if (this.state.isSubmitting) {
            return
        }
        const requestedData = this.props.caseInfo.contactData
        this.setSubmitting(true)
        this.validateAllUntouched().then(() => {
            if (isValidForm(this.state.userData, requestedData)) {
                this.props.submitData(preparePayload(this.state.userData, requestedData))
                    .then(() => this.setSubmitting(false))
                    .then(smoothScrollToTop)
            } else {
                this.setSubmitting(false)
            }
        })
    }

    setSubmitting = isSubmitting => this.setState(() => ({isSubmitting}))

    validateAllUntouched = () => {
        const requestedData = this.props.caseInfo.contactData
        return Promise.all(
            requestedData.map(requested => {
                const userData = this.state.userData[requested.key]
                if (requested.mandatory && !userData) {
                    return this.validateUserData(requested.key, requested.mandatory)
                }
                return Promise.resolve()
            })
        )
    }

    validateUserData = (key, mandatory, value, errorText) => {
        if (mandatory && !value) {
            errorText = getI18nTextWithContext(this.context, 'dataCompletion.validation.missing')
        }
        return new Promise(resolve => {
            this.setState(state => ({
                userData: Object.assign({}, state.userData, {
                    [key]: {
                        value,
                        errorText,
                        validated: !!errorText
                    }
                })
            }), resolve)
        })
    }

    render() {
        const formGroups = this.props.caseInfo.contactData
            .sort(sortData)
            .map(data => <FormGroup data={data}
                                    onChange={this.validateUserData.bind(this, data.key, data.mandatory)}
                                    key={data.key}
                                    enteredUserData={this.state.userData[data.key]}/>)
        return <>
            <div className="row">
                <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                    <Title heading={<Text k="dataCompletion.heading"/>}
                           subHeading=''
                           testId="70a6d638-d0ef"/>
                    <form onSubmit={this.submitData} onKeyDown={this.onFormKeyPress}>
                        {formGroups}
                        <button className="btn btn-primary w-100 mt-3" disabled={this.state.isSubmitting}
                                data-test-id="03d90225-b547">
                            <Text k="common.continue"/>
                        </button>
                    </form>
                </div>
            </div>
            <ShortCaseInfo bcName={this.props.caseInfo.principalDisplayName}/>
        </>
    }
}

DataCompletion.propTypes = {
    caseInfo: PropTypes.shape({
        contactData: PropTypes.arrayOf(
            PropTypes.shape({
                sortKey: PropTypes.number.isRequired
            })
        ).isRequired,
        principalDisplayName: PropTypes.string.isRequired
    }).isRequired,
    submitData: PropTypes.func.isRequired,
    serverValidationErrors: PropTypes.arrayOf(
        PropTypes.shape({
            parameterName: PropTypes.string.isRequired,
            errorText: PropTypes.string.isRequired
        })
    )
}

function isValidForm(userData, requestedData) {
    return requestedData
        .reduce((accumulator, requested) => accumulator && isValidData(requested, userData[requested.key]), true)
}

function isValidData(requested, userData) {
    return !userData?.errorText
}

function sortData(d1, d2) {
    return d1.sortKey - d2.sortKey
}

function preparePayload(userData, requestedData) {
    const payload = {}
    Object.keys(userData).forEach(key => payload[key] = userData[key].value)
    requestedData.forEach(data => {
        payload[data.key + 'Provided'] = !!payload[data.key]
    })
    return payload
}